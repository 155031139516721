/** @format */


import styled from "styled-components";
import bg from "../../images/chbg.png"
import blentop from "../../images/misionblen.png"
import BlendBottom from "../../images/blendbottom.png"

const Wrapper = styled.div `
.about{
    background: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 120px 0px;
    /* height: 80vh; */
    display: flex;
    align-items: center;
    overflow-x: hidden;
    z-index: 21;
}
.mision_blend{
    background: url(${blentop});
    position: absolute;
    top: 0;
    bottom: auto;
    height: 120px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 25;
    filter: blur(20px);
}
.mision_blend2{
    background: url(${BlendBottom});
    top: auto;
    filter: blur(0);
    bottom: 0;
}
.row{
    align-items: center;
}
.col-md-6{
    /* margin-left: 50%; */
    padding-right: 20px;
    
}
.about_content{
    color: white;
    padding-right: 20px;
    position: relative;
    z-index: 99999;
}
.about_content h3{
    font-size: 36px;
    -webkit-letter-spacing: 6px;
    -moz-letter-spacing: 6px;
    -ms-letter-spacing: 6px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: capitalize;
    font-weight: 500;

}
.about_content h6{
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 8px;
}
.about_content p{
    color: #E1E1E1;
    padding-top: 15px;
    line-height: 33px;
    padding-bottom: 25px;
}
.earthImg{
    padding-right: 0px;
    text-align: center;
    position: relative;
    z-index: 99999;
}
.earthImg img{
    width:55%;
}
.title{
 
    padding-bottom: 40px;
}

.col-md-12{
    display: flex;
    justify-content: center;
}
.aboutButton{
    margin-top: 20px;
}

@media (max-width: 1192px) {
    .col-md-7 {
    flex: 0 0 50.333333%;
    max-width: 58.333333%;
}
.col-md-5 {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
}
 .about_content h3 {
    font-size: 34px;

  
}
    
}
@media (max-width: 992px) {
 .about {

    padding: 80px 0px;
  
}
 .about_content h3 {
    font-size: 23px;
    letter-spacing: 3px;

  
}
 .about_content p {
line-height: 27px;

    font-size: 13px;
}
    
}

@media (max-width: 767.98px) {

.about_content h3 {
   font-size: 22px;
   letter-spacing: 1px;

 
}
 .col-md-7 {
   
    flex: 0 0 100%;
    max-width: 100%;
}
 .col-md-5 {

    flex: 0 0 100%;
    max-width: 100%;
}  
.row{
    flex-direction: column-reverse;
}
.earthImg {
    padding-right: 0;
}
.earthImg img {
    width: 35%;
}
.about_content {
  text-align: center;
    margin-top: 20px;
}
}


@media (max-width: 575.98px) {
.earthImg img {
    width: 45%;
}
    .container-fluid2 {
    padding-right: 15px;
    padding-left: 15px;
}
.about_content h3 {
   font-size: 20px;
   letter-spacing: 0px;

 
}
 .col-md-7 {
   
    flex: 0 0 100%;
    max-width: 100%;
}
 .col-md-5 {

    flex: 0 0 100%;
    max-width: 100%;
}  
.earthImg {
    padding-right: 0;
    padding-right: 15px;
}
.about_content {

    padding-right: 20px;
    text-align: center;
    margin-top: 40px;
    padding: 0px 15px;
}
}
.default-btn {
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 15px 30px 16px;
    line-height: 1;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-align: center;
    background-color: #e21f36;
    position: relative;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-decoration: none;
    /* color: #4b5280; */
}
.default-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,right top,left top,from(#ffffff),to(transparent));
    background: linear-gradient(right,#ffffff,transparent);
    -webkit-animation: right 2s linear infinite;
    animation: right 2s linear infinite;
}
.default-btn span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(transparent));
  background: linear-gradient(top, #ffffff, transparent);
  animation: top 2s linear infinite;
  animation-delay: 1s;
}

.default-btn span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
  background: linear-gradient(bottom, #ffffff, transparent);
  animation: bottom 2s linear infinite;
  animation-delay: 1s;
}
.default-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,left top,right top,from(#ffffff),to(transparent));
    background: linear-gradient(left,#ffffff,transparent);
    -webkit-animation: left 2s linear infinite;
    animation: left 2s linear infinite;
}
@keyframes right {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
    
}
@keyframes top {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
    
}
@keyframes bottom {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
    
}
@keyframes left {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

    
}

`;
export default Wrapper;
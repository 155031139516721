/** @format */

import Bg from "../../images/partner.jpg"
import styled from "styled-components";

const Wrapper = styled.div`

.partner{
    padding: 120px 0px;
    background: url(${Bg}),    linear-gradient(
      rgba(255, 0, 0, 0.45), 
      rgba(255, 0, 0, 0.45)
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
.col-md-12{
    display: flex;
    justify-content: center;
}

.slider_img {
    margin-top: 50px;
    /* width: 180px; */
    /* height: 150px; */
    display: flex;
justify-content: center;
  
    border: 2px solid white;

    border-radius: 8px;
}
.slider_img img{
    height: 90px;
    padding: 0px 20px;
    object-fit: contain;
}
@media (max-width: 992px) {
    .slider_img img{
    height: 60px;
 
}
    
}
@media (max-width: 575.98px) {
    .container-fluid2 {

    padding-right: 35px;
    padding-left: 35px;
    
}
.slider_img img{
    height: 50px;
    padding: 10px 10px;
 
}.partner {
    padding: 80px 0px;

}
}
`;
export default Wrapper;

import React from 'react'
import Wrapper from './Partner.styled'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

// images

import Binance from "../../images/binance.png"
import Meta from "../../images/metanew.png"
import Trust from "../../images/trustv1.png"
import Product from "../../images/pocket.png"
import audit from "../../images/audit.png"



function Partner() {
  return (
  <Wrapper>
<div className="partner">
<div className="col-md-12">
    <div className="title">
    <h3 className="common_title   gradient3">
                   <span>O</span><span>u</span><span>r</span> <span>P</span><span>a</span><span>r</span><span>t</span><span>n</span><span>e</span><span>r</span><span>s</span><span className='blink-soft  gradient3'>_</span>
                    </h3>
    </div>
 </div>
 <div className="container-fluid2">
    <div className="row">
    <Swiper
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
            16: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1192: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
          }}
        autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
            
            <div className="slider_img">
                <img src={Binance} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            
            <div className="slider_img">
                <img src={Meta} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            
            <div className="slider_img">
                <img src={Product} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            
            <div className="slider_img">
                <img src={Trust} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            
            <div className="slider_img">
                <img src={Meta} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>

        <SwiperSlide>
            
            <div className="slider_img">
                <img src={audit} className='img-fluid' loading='lazy' alt="logo" />
            </div>
        </SwiperSlide>
      
     
      </Swiper>
    </div>
 </div>
</div>
  </Wrapper>
  )
}

export default Partner

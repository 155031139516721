/** @format */


import styled from "styled-components";
import Bg from "../../images/banner2.png"
const Wrapper = styled.div `

.banner{
/* background: url(${Bg}); */
min-height: 95vh;
background-size: cover;
background-position: bottom;
position: relative;
overflow: hidden;
display: flex;
align-items: center;
@media (max-width: 1192px) {
    padding: 90px 0px;
    
}
}


.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  overflow: hidden;
}

.background-video video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45); /* Black overlay with 5% opacity */
  z-index: 1;
}
.BackgroundVideo2 {
    overflow: visible;
    opacity: 0.5;
}
.banner__img{
    text-align: center;
    position: relative;
}
.banner__img img{
    width: 65%;
    position: relative;
    z-index: 132;

}
.box__shadow{
    width: 210px;
    height: 190px;
    box-shadow: 0px 0px 85px 0px rgba(187,237,43,0.75);
    -webkit-box-shadow: 0px 0px 85px 0px rgba(187,237,43,0.75);
    -moz-box-shadow: 0px 0px 85px 0px rgba(187,237,43,0.75);
    background-color: yellow;
    position: absolute;
    top: 50px;
    left: 32%;
    z-index: 2;
    filter: blur(70px);
}

.row{
    align-items: center;
    @media (max-width: 992px) {
        flex-direction: column-reverse;
        
    }
}

.banner_Title{}
.banner_Title h3{
    font-size: 46px;
    font-weight: 700;
    color: white;
  
}
.banner_Title h3 span{
    /* background-clip: text;
  color: transparent; */
/* background-color: #26BFED; */
}
.banner_pera{
    padding: 20px 0px;
    
}
.banner_pera p{
    color: #E1E1E1;
    line-height: 30px;
}
.banner_button{
    display: flex;
    column-gap: 20px;
   
}

.banner_img{
    position: absolute;
    width: 52%;
    left: auto;
    top: 0;
    right: 0;
    top: auto;
    bottom: -10px;
    z-index: 22;
}
.mobileCOl{
    display: none;
}
@media (max-width: 1192px) {
 .banner_Content_single_item {
    padding-bottom: 40px;
}
 .banner_Title h3 {
    font-size: 35px;
    
}
.banner_button a {
   
    min-width: 184px;

}
 .banner_img {

    width: 50%;

    bottom: -6px;

}

}
@media (max-width: 992px) {
    .mobileCOl{
        display: block;
    }
 .mobile_img{
    text-align: center;
 }
 .mobile_img img{
    width: 65%;
 }
    .right_single_item{
        display: none;
    }
 .banner {
padding-top: 110px;
    min-height: auto;
 
}
    .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
.container-fluid2 {
    
    padding-right: 40px;
    padding-left: 40px;

}
.banner_Content_single_item {
   padding-top: 20px;
    text-align: center;
}
.banner_button {

    justify-content: center;
}
}
@media (max-width: 575.98px) {
 
 .mobile_img img{
    width: 100%;
 }
    .right_single_item{
        display: none;
    }
 .banner {
padding-top: 80px;
    min-height: auto;
 
}

.container-fluid2 {
    
    padding-right: 15px;
    padding-left: 15px;

}
.banner_Content_single_item {
   padding-top: 20px;
    text-align: center;
}
.banner_Title h3 {
    font-size: 25px;
}
.banner_button {

    display: block;
    padding: 0px 15px;
}
 .banner_button a {

    width: 224px;
    margin-bottom: 20px;
}
}

.boxshadow,.boxshadow2{
    position: absolute;
    left: auto;
    right: 32%;
    height: 80px;
    width: 18%;
    background-color: wheat;
    background-color: #020202;
    ebkit-box-shadow: 2px -19px 48px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px -19px 48px -4px rgba(0,0,0,0.75);
    box-shadow: 2px -19px 48px -4px rgba(0,0,0,0.75);
    top: auto;
    bottom: 35px;
    z-index: 546;
    filter: blur(12px);
    @media (max-width: 1192px) {
        display: none;
        
    }
}
.boxshadow2{
    left: auto;
    right: 0;
}
@media (max-width: 1192px) {
 .boxshadow {

    right: 32%;
    height: 55px;
    width: 35%;
   
    bottom: 25px;
   
}
.boxshadow2{
    left: auto;
    right: 0;
}
    
}
@media (max-width: 1192px) {
.box__shadow {
    width: 180px;
    height: 150px;

}
    
}
@media (max-width: 992px) {
    .banner__img img {
    width: 45%;

}
    .boxshadow,.boxshadow2{
        display: none;
    }
    
 .box__shadow {
    width: 140px;
    height: 120px;
    top: 40px;
    left: 39%;

}

}
@media (max-width: 575.98px) {
    .mobileCOl{
        display: none;
    }
    
}
@media (max-width: 767.98px) {
 .banner__img img {
    width: 50%;

}

 .box__shadow {
    width: 160px;
    height: 150px;

    top: 40px;
    left: 32%;

}

}
@media (max-width: 575.98px) {
    .banner__img img {
    width: 70%;

}
 .box__shadow {
    width: 100px;
    height: 100px;
    top: 40px;
    left: 37%;
    filter: blur(51px);
}
}

.default-btn {
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 15px 20px 16px;
    line-height: 1;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-align: center;
    background-color: #e21f36;
    position: relative;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-decoration: none;
    /* color: #4b5280; */
}
.default-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,right top,left top,from(#ffffff),to(transparent));
    background: linear-gradient(right,#ffffff,transparent);
    -webkit-animation: right 2s linear infinite;
    animation: right 2s linear infinite;
}
.default-btn span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(transparent));
  background: linear-gradient(top, #ffffff, transparent);
  animation: top 2s linear infinite;
  animation-delay: 1s;
}

.default-btn span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
  background: linear-gradient(bottom, #ffffff, transparent);
  animation: bottom 2s linear infinite;
  animation-delay: 1s;
}
.default-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,left top,right top,from(#ffffff),to(transparent));
    background: linear-gradient(left,#ffffff,transparent);
    -webkit-animation: left 2s linear infinite;
    animation: left 2s linear infinite;
}
@keyframes right {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
    
}
@keyframes top {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
    
}
@keyframes bottom {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
    
}
@keyframes left {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

    
}
.canvAnim{
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
@media (max-width: 992px) {
    width: 70%;
    
}
@media (max-width: 575.98px) {
    width: 100%;
    
}
}


`;
export default Wrapper;
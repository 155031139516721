import React, { useState } from 'react';
import Wrapper from './About.styled'
import Earth from "../../images/charecter.png"
import AOS from 'aos';
import VisibilitySensor from 'react-visibility-sensor';
import 'aos/dist/aos.css';
function About() {


  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
      AOS.refresh();
    }
  };
  return (
 <Wrapper>


<VisibilitySensor onChange={onVisibilityChange} partialVisibility>

<section className="about" >
  <div className="container-fluid2">


<div className="row">

    <div className="col-md-7">

<div className="about_content"data-aos="fade-up" data-aos-duration="3000">
  <h6>MISSION</h6>
      <h3>
      Integrity : We believe and stand by what we commit to our clients, maintaining the security and privacy of their information.
      </h3>
      <p>Ethan, A blockchain-based decentralised play-to-earn NFT Car - Racing game built on Binance Smart Chain, where players will Ethan each other to win rewards in real time. The rewards won by the players in the form of tokens can be used both inside the game and traded outside the game in the crypto markets.</p>
      <p>Quality
We believe in excellence and thus, strive to achieve superior quality in all our work, solutions, and products we deliver.</p>
<p>
Customer Satisfaction
We strive to achieve customer satisfaction by delivering solutions that meet their expectations in terms of quality, budget, and time.
</p>

      <div className="banner_button aboutButton">
      <a href="#" className='default-btn'><span>Learn More</span></a>

    </div>
  </div>
</div>
<div className="col-md-5">
<div className="earthImg"data-aos="zoom-in" data-aos-duration="1000">
        <img src={Earth} loading='lazy' className='img-fluid' alt="earth" />
    </div>
</div>
      
        </div>
        </div>
        <div className="mision_blend">

    </div>
    <div className="mision_blend mision_blend2">

</div>
</section>

</VisibilitySensor>

    </Wrapper>
  )
}

export default About

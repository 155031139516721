import React, { useState, useEffect } from 'react';
import Wrapper from './Navbar.styled'
import Logo from "../../images/ethan.gif"
import Logo2 from "../../images/ethanText.png"
import { NavHashLink as Link } from 'react-router-hash-link';
import MobileMenu from '../MobileMenu/MobileMenu';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
function Navbar() {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
<Wrapper>
<nav className={scrollPosition > 50 ? 'navbar scrolled' : 'navbar'}>
    <div className="container-fluid2">
        <div className="row">
            <div className="navbarSingleItem">
                <div className="nav_logo"data-aos="fade-down" data-aos-duration="1000">
<a href="/"><img src={Logo} className='img-fluid imgFluid1' loading='lazy' alt="logo" /></a>
<a href="/"><img src={Logo2} className='img-fluid imgFluid2' loading='lazy' alt="logo" /></a>
                </div>
                <div className="menu">
                    <ul>

                    <li data-aos="fade-down" data-aos-duration="1200">
                    <Link smooth to="/#">
          Home
        </Link>
                    </li>
                    <li data-aos="fade-down" data-aos-duration="1400">
                    <Link smooth to="/#feature">
          Features
        </Link>
                    </li>
                    <li  data-aos="fade-down" data-aos-duration="1600">
                    <Link smooth to="/#roadmap">
          Roadmap
        </Link>
        
                    </li>
                    <li data-aos="fade-down" data-aos-duration="1800">
                    <Link smooth to="/#tokenmics">
          Tokenmics
        </Link>
        
                    </li>
                    <li data-aos="fade-down" data-aos-duration="2000">
                    <Link smooth to="/#community">
                    Community
        </Link>
                    </li>
           
                    </ul>
                </div>
                <div className="nav_button">
                    <a href="https://drive.google.com/file/d/1NHr3mRfuID_cfeY0RLjIB7qNzwydXjox/view?usp=sharing" target='blank' data-aos="fade-down" data-aos-duration="2200">Ethan Profile</a>
                    <a href="https://whitepaper.ethantoken.io" data-aos="fade-down" data-aos-duration="2400" target='blank'>WhitePaper</a>
                    <a href="https://drive.google.com/file/d/1_-APR_09YptBuDxkmiQ49o3XOATvofjb/view?usp=sharing" data-aos="fade-down" data-aos-duration="2400" target='blank'>Ethan Report</a>
                </div>
            </div>
        </div>
    </div>
</nav>
<MobileMenu />
</Wrapper>
  )
}

export default Navbar

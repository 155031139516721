import styled from "styled-components";

const Wrapper = styled.div `
.spinerLogo{
  position: absolute;
  width: 14%;
  @media (max-width: 992px) {
    width: 18%;
    
  }
  @media (max-width: 767.98px) {
    width: 26%;
    
  }
}
.spinerLogo img{
  width: 100%;
}
`;

export default Wrapper;
// OffCanvasMenu.js

import React, { useState, useEffect } from 'react';
import Logo from "../../images/ethan.gif"
import Logo2 from "../../images/ethanText.png"
import { Link, animateScroll as scroll } from 'react-scroll';
import Wrapper from './MobileMenu.styled';
import { Icon } from '@iconify/react';

// import { Link } from 'react-router-dom';
const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
    scroll.scrollToTop({ smooth: true }); // Scrolls to the top of the page with smooth scrolling
  };


  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Wrapper  className={scrollPosition > 50 ? 'navbar scrolled' : 'navbar'}>
             <div className="nav_logo"data-aos="fade-down" data-aos-duration="1000">
<a href="/"><img src={Logo} className='img-fluid imgFluid1' loading='lazy' alt="logo" /></a>
<a href="/"><img src={Logo2} className='img-fluid imgFluid2' loading='lazy' alt="logo" /></a>
                </div>
    <div className={`offcanvas-menu ${isMenuOpen ? 'open' : ''}`}>
    <button onClick={toggleMenu} className="toggle-button">
        {isMenuOpen ? (
          <span className='icon'>
<Icon icon="carbon:close" />
          </span>
        ) : (
       <span className='icon'>
           <Icon icon="fe:bar" />
       </span>
        )}
      </button>

      <div className="menu-content">
        <ul>
          <li>
            <Link
              to="home"
              smooth={true}
              onClick={handleMenuItemClick}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="feature"
              smooth={true}
              onClick={handleMenuItemClick}
            >
              Features
            </Link>
          </li>
          <li>
            <Link
              to="roadmap"
              smooth={true}
              onClick={handleMenuItemClick}
            >
              Roadmap
            </Link>
          </li>
          
          <li>
            <Link
              to="tokenmics"
              smooth={true}
              onClick={handleMenuItemClick}
            >
              Tokenmics
            </Link>
          </li>
          <li>
            <Link
              to="/"
              smooth={true}
              onClick={handleMenuItemClick}
            >
              Community
            </Link>
          </li>
        </ul>
        <div className="mobileButton">
                    <a href="https://drive.google.com/file/d/1NHr3mRfuID_cfeY0RLjIB7qNzwydXjox/view?usp=sharing" target='blank'>Ethan Profile</a>
                    <a href="https://whitepaper.ethantoken.io/" target='blank'>WhitePaper</a>
                    <a href="https://drive.google.com/file/d/1_-APR_09YptBuDxkmiQ49o3XOATvofjb/view?usp=sharing" target='blank'>Ethan Report</a>
                </div>
      </div>
      {isMenuOpen && (
        <div className="close-icon" onClick={toggleMenu}>
          Close Icon ✕
        </div>
      )}
    </div>
    </Wrapper>
  );
};

export default MobileMenu;

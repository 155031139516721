import React from 'react'
import Wrapper from './Vision.styled'
import VisionImg from "../../images/vision.png"
function Vision() {
  return (
<Wrapper>
<section className="vision">
<div className="container-fluid2">
    <div className="row">
        <div className="col-md-6">
            <div className="vision_img">
                <img src={VisionImg} className='img-fluid' alt="" />
            </div>
        </div>
        <div className="col-md-6">
            <div className="vision_content">
                <h4>Our vision</h4>
                <p>Top New Technology Trends for 2023 To Watch Out For
Technology trends represent the latest shifts in the industry, and with the internet’s influence, these changes occur rapidly. For professionals in the software industry, staying updated with these trends is crucial. As we progress into 2023, there are emerging technology trends that will shape our futuristic world.</p>

<div className="banner_button vision__buton"data-aos="fade-up" data-aos-duration="2500">
                <a href="https://bscscan.com/token/0xf9f3336975a587688e6d37b0f36a4e7a0d209abc" target="_blank" className='default-btn'><span>Learn More</span></a>
              </div>
            </div>
        </div>
    </div>
 </div>
 <div className="blend">

</div>
<div className="blend blend2">

</div>
</section>

</Wrapper>
  )
}

export default Vision

import React from 'react'
import Wrapper from './Marque.styled'
import Slider from "../../images/slider.png"
import Slider2 from "../../images/slider2.png"
import Slider3 from "../../images/slider3.png"
import Slider4 from "../../images/slider4.png"
import Slider5 from "../../images/slider5.png"
import Star from "../../images/star.png";

function Marque() {
  return (
    <Wrapper>
   <div className="slider">
        <div className="slide-track">

        {/* single slide item start */}

          <div className="slide">
            <a href="https://whitepaper.ethantoken.io/ethan-upcomings/nft-marketplace/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Ethan NFT</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


       {/* single slide item start */}

	   <div className="slide">
            <a href="https://whitepaper.ethantoken.io/tokenomics/ethan/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Ethan Token</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/artificial-intelligence-(ai)-and-machine-learning-(ml)/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Artificial Intelligence (AI) and Machine Learning (ML)</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/internet-of-things-(iot)/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>⁠Internet of Things (IoT)</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/5g/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>5G</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/virtual-and-augmented-reality/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Virtual and augmented reality (VR/AR)</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/robotic-process-automation/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Robotic Process Automation (RPA)</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/blockchain/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Blockchain</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/quantum-computing/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Quantum Computing</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/datafication/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Datafication</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/renewable-energy/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Renewable Energy</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/autonomous-vehicles/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Autonomous Vehicles</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/digital-twins/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Digital Twins</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/edge-computing/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Edge Computing</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


		         {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/cybersecurity/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Cybersecurity</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


             {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/3d-printing/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>3D Printing</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}


             {/* single slide item start */}

				 <div className="slide">
            <a href="https://whitepaper.ethantoken.io/future-timeline/human-augmentation/" target='_blank'>
              <div className="slider_text">
                <div className="star">
                  <img src={Star} className='img-fluid' alt="star" />
                </div>
                <div className="text_slide">
                  <h4>Human Augmentation</h4>
                </div>
              </div>
            </a>
          </div>

		  {/* single Slide Item */}
        </div>
      </div>
    </Wrapper>
  )
}

export default Marque

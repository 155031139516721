/** @format */


import styled from "styled-components";
import Bg from "../../images/bg_token.png"
const Wrapper = styled.div`
.tokenmics{
    padding-top: 30px;
    padding-bottom: 80px;
background: url(${Bg});
background-size: cover;
background-repeat: no-repeat;
display: flex;
align-items: center;

}
.title{
    padding-bottom: 60px;
}
.col-md-12{
    display: flex;
    justify-content: center;
}
.container-fluid2{
padding-left: 10px;
padding-right: 50px;
}
.row{
    align-items: center;
}
.token_Deta_item{
    padding: 0px 40px 0px 15px;
}

.token_content{}
.token_content h5{
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-align: center;
}
.token_content h4{
    font-size: 16px;
    font-weight: 700;
    color: white;
}
.token_common_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
padding: 10px 25px;
background-color: #102644;
border-radius: 80px;

border: 1px solid beige;
    -webkit-box-shadow: -8px 48px 109px 130px rgba(255,255,255,1);
    -moz-box-shadow: -8px 48px 109px 130px rgba(255,255,255,1);
    box-shadow: 9px 3px 25px -13px rgb(47 158 240);
    margin-bottom: 30px;


 
}
.name{
    flex:  0 0 35%;
    max-width: 35%;
}
.name h4{}
.symbol{
    flex: 0 0 30%;
    max-width: 30%;
}
.symbol h4{
    text-align: center;
}
.token{
    flex:  0 0 35%;
    max-width: 35%;
}
.token h4{
    text-align: end;
}
@media (max-width: 1192px) {


 .token_Deta_item {
    padding: 0px 10px 0px 15px;
}
    
}
@media (max-width: 992px) {


.token_Deta_item {
   padding: 0px 5px 0px 5px;
}
 .token_content h4 {
    font-size: 11px;

}
.token_common_item {

    margin-bottom: 20px;
}
   
}
@media (max-width: 767.98px) {


    .col-md-7,.col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
}
 .token_content h4 {
    font-size: 11px;

}
.token_common_item {

    margin-bottom: 20px;
}
   .container-fluid2{
    padding-left: 15px;
    padding-right: 15px;
   }
}
.tokemics_elipse{
    position: relative;
    width: 670px;
}
.circle1{
    transform: translate(0px, 0px);
}
 .circle,  .circle::before {
    content: " ";
    margin: 15px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    /* margin: 0 auto; */
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: white;
    position: absolute;
    top: 25.5%;
    left: 43.2%;
    /* transform: translate(10px, 10px); */
}
.circle::before {
    content: '';
    -webkit-animation: mymove 2s infinite;
    animation: mymove 2s infinite;
    position: absolute;
background-color: white;
    left: -15px;
    top: -15px;
}

@keyframes mymove {
    

  50%   {
    transform: scale(2);
    opacity: 0
  }
  100%   {
    transform: scale(2);
    opacity: 0
  }
}
.circle2{
    left: 65.6%;
    top: 30.4%;

}
.circle3{
    left: 34.4%;
    top: 38.8%;
    transform: translate(1px, -0.5px);

}
.circle4{
    left: 37.4%;
    top: 56%;
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    transform: translate(-1px,2px)

}

.circle5{
    left: 48.4%;
    top: 65%;
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    transform: translate(-4px,-3px);

}
.circle6{
    left: 67.4%;
    top: 56%;
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    transform: translate(-5px,-7px);

}
@media (max-width: 1192px) {
 .tokemics_elipse {

    width: 580px;
}
 .circle1 {
    -webkit-transform: translate(0px,0px);
    -ms-transform: translate(0px,0px);
    transform: translate(-4px,-2px);
}
 .circle3 {
 
    -webkit-transform: translate(1px,-0.5px);
    -ms-transform: translate(1px,-0.5px);
    transform: translate(-3px,-2.5px);
}
    
 .circle4 {

    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    transform: translate(-3px,-2px);
}
.circle5 {
   
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    transform: translate(-6px,-3px);
}
.circle6 {
    
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    -webkit-transform: translate(-5px,-7px);
    -ms-transform: translate(-5px,-7px);
    transform: translate(-7px,-8px);
}
 .circle2 {
    left: 65.6%;
    top: 30.4%;
    transform: translate(-2px, -3px);
}
}
@media (max-width: 992px){

.tokemics_elipse {
    width: 420px;
}
.circle,.circle::before {
   
    width: 5px;
    height: 5px;
   
}
.circle1 {
    -webkit-transform: translate(0px,0px);
    -ms-transform: translate(0px,0px);
    -webkit-transform: translate(-4px,-2px);
    -ms-transform: translate(-4px,-2px);
    transform: translate(-6px,-7px);
}
.circle3 {
    -webkit-transform: translate(1px,-0.5px);
    -ms-transform: translate(1px,-0.5px);
    -webkit-transform: translate(-3px,-2.5px);
    -ms-transform: translate(-3px,-2.5px);
    transform: translate(-5px,-6.5px);
}
.circle4 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-3px,-2px);
    -ms-transform: translate(-3px,-2px);
    transform: translate(-6px,-7px);
}
.circle5 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    -webkit-transform: translate(-6px,-3px);
    -ms-transform: translate(-6px,-3px);
    transform: translate(-9px,-9px);
}
 .circle6 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    -webkit-transform: translate(-5px,-7px);
    -ms-transform: translate(-5px,-7px);
    -webkit-transform: translate(-7px,-8px);
    -ms-transform: translate(-7px,-8px);
    transform: translate(-9px,-12px);
}
.circle2 {

    -webkit-transform: translate(-2px,-3px);
    -ms-transform: translate(-2px,-3px);
    transform: translate(-6px,-7px);
}
}
@media (max-width: 767.98px) {
    .tokemics_elipse{
        margin: 0 auto;
    }
    
}
@media (max-width: 575.98px) {
 .tokemics_elipse {
    width: 280px;
}
 .circle1 {
    -webkit-transform: translate(0px,0px);
    -ms-transform: translate(0px,0px);
    -webkit-transform: translate(-4px,-2px);
    -ms-transform: translate(-4px,-2px);
    -webkit-transform: translate(-6px,-7px);
    -ms-transform: translate(-6px,-7px);
    transform: translate(-9px,-11px);
}
.circle2 {
    -webkit-transform: translate(-2px,-3px);
    -ms-transform: translate(-2px,-3px);
    -webkit-transform: translate(-6px,-7px);
    -ms-transform: translate(-6px,-7px);
    transform: translate(-9px,-12px);
}
 .circle3 {
    -webkit-transform: translate(1px,-0.5px);
    -ms-transform: translate(1px,-0.5px);
    -webkit-transform: translate(-3px,-2.5px);
    -ms-transform: translate(-3px,-2.5px);
    -webkit-transform: translate(-5px,-6.5px);
    -ms-transform: translate(-5px,-6.5px);
    transform: translate(-9px,-11.5px);
}
.circle4 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-3px,-2px);
    -ms-transform: translate(-3px,-2px);
    -webkit-transform: translate(-6px,-7px);
    -ms-transform: translate(-6px,-7px);
    transform: translate(-9px,-12px);
}
.circle5 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    -webkit-transform: translate(-6px,-3px);
    -ms-transform: translate(-6px,-3px);
    -webkit-transform: translate(-9px,-9px);
    -ms-transform: translate(-9px,-9px);
    transform: translate(-12px,-15px);
}
 .circle6 {
    -webkit-transform: translate(0px,-0.5px);
    -ms-transform: translate(0px,-0.5px);
    -webkit-transform: translate(-1px,2px);
    -ms-transform: translate(-1px,2px);
    -webkit-transform: translate(-4px,-3px);
    -ms-transform: translate(-4px,-3px);
    -webkit-transform: translate(-5px,-7px);
    -ms-transform: translate(-5px,-7px);
    -webkit-transform: translate(-7px,-8px);
    -ms-transform: translate(-7px,-8px);
    -webkit-transform: translate(-9px,-12px);
    -ms-transform: translate(-9px,-12px);
    transform: translate(-11px,-15px);
}
}
`;
export default Wrapper;

import React, { useEffect } from 'react';
import Wrapper from './Tokenmics.styled'
import Elipse from "../../images/ellise2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
function Tokenmics() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS with your preferred options
      }, []);
  return (
 <Wrapper id='tokenmics'>
  <section className="tokenmics" >
    <div className="container-fluid2">
        <div className="row">
            <div className="col-md-12">
                <div className="title">
                    <h3 className="common_title   gradient3">
                   <span>T</span><span>O</span><span>K</span><span>E</span><span>N</span><span>O</span><span>M</span><span>I</span><span>C</span><span>S</span><span className='blink-soft  gradient3'>_</span>
                    </h3>
                </div>
            </div>
            <div className="col-md-7">
                <div className="tokemics_elipse" data-aos="zoom-in" data-aos-duration="1200">
                    <img src={Elipse} className='img-fluid' loading='lazy' alt="elipse" />

                    <div className="circle circle1">
                        
                    </div>
                    <div className="circle circle2">
                        
                        </div>
                        
                        <div className="circle circle3">
                        
                        </div>

                          
                        <div className="circle circle4">
                        
                        </div>

                                  
                        <div className="circle circle5">
                        
                        </div>
                        <div className="circle circle6">
                        
                        </div>
                </div>
            </div>

            <div className="col-md-5">
                <div className="token_Deta_item">
                    <div className="token_content">
                        <h5>TOKEN DETAILS</h5>
                    {/* single item start here */}
                        <div className="token_common_item"data-aos="fade-up" data-aos-duration="1400">
                            <div className="name">
                                <h4>Name</h4>
                            </div>
                            <div className="symbol">
                                <h4>-</h4>
                            </div>
                            <div className="token">
                                <h4>Ethan Token</h4>
                            </div>
                        </div>
                        {/* single item end */}

                                         {/* single item start here */}
                                         <div className="token_common_item"data-aos="fade-up" data-aos-duration="1600">
                            <div className="name">
                                <h4>Symbol</h4>
                            </div>
                            <div className="symbol">
                                <h4>-</h4>
                            </div>
                            <div className="token">
                                <h4>ETHAN</h4>
                            </div>
                        </div>
                        {/* single item end */}
                                         {/* single item start here */}
                                         <div className="token_common_item"data-aos="fade-up" data-aos-duration="1800">
                            <div className="name">
                                <h4>Total Supply</h4>
                            </div>
                            <div className="symbol">
                                <h4>-</h4>
                            </div>
                            <div className="token">
                                <h4>210,000,000</h4>
                            </div>
                        </div>
                        {/* single item end */}
                                         {/* single item start here */}
                                         <div className="token_common_item"data-aos="fade-up" data-aos-duration="2000">
                            <div className="name">
                                <h4>Contract</h4>
                            </div>
                            <div className="symbol">
                                <h4>-</h4>
                            </div>
                            <div className="token">
                                <h4><a href="https://bscscan.com/token/0xf9f3336975a587688e6d37b0f36a4e7a0d209abc" target="_blank" rel="noopener noreferrer"style={{ color: 'white' }}>0xF9209Abc</a></h4>
                            </div>
                        </div>
                        {/* single item end */}
                                         {/* single item start here */}
                                         <div className="token_common_item"data-aos="fade-up" data-aos-duration="2200">
                            <div className="name">
                                <h4>Blockchain</h4>
                            </div>
                            <div className="symbol">
                                <h4>-</h4>
                            </div>
                            <div className="token">
                                <h4>BSC</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
 </Wrapper>
  )
}

export default Tokenmics

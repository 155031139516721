/** @format */


import styled from "styled-components";
import bg from "../../images/visionbg.png"
import blend from "../../images/blend.png"
import blend2 from "../../images/blend2.png"
const Wrapper = styled.div `
position: relative;
.vision{
background:  url(${bg});
background-color: black;
background-size: cover;
background-repeat: no-repeat;
padding: 90px 0px;
position: relative;

z-index: 23;
@media (max-width: 575.98px) {
    padding: 10px 0px;
    overflow: hidden;
}
}
.blend{
    background: url(${blend});

    height: 220px;
    position: absolute;
    top: auto;
    bottom: -2px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 6565;
}
.blend2{
    background: url(${blend2});

    bottom: auto;
    top: 0;
    height: 350px;
    top: -120px;
    z-index: 212;
}
.row{
    align-items: center;
    @media (max-width: 767.98px) {
        flex-direction: column-reverse;
        
    }
}
.vision_content{
    color: white;
    position: relative;
    z-index: 6995;
    text-align: right;
}
.vision_content h4{
    padding-bottom: 20px;
    font-size: 35px;
    font-weight: 500;
}
.vision_content p{
    line-height: 33px;
    font-size: 17px;
}
.vision__buton{
    column-gap: 20px;
    display: flex;
    justify-content: end;

    margin-top: 30px;
}
.vision_img{
    text-align: left;
    position: relative;
    z-index: 9878;
}
.vision_img img{
    width: 70%;
    @media (max-width: 1192px) {
        width: 60%;
        
    }
    @media (max-width: 992px) {
        width: 75%;
        
    }
}
@media (max-width: 767.98px) {
    .col-md-6{
        flex: 0 0 100%;
    max-width: 100%;

    }
    .vision_img{
        text-align: center;
    position: relative;
    top: 20px;
    padding-top: 20px;

    }
    .vision_img img{
        width: 48%;
    }
.vision_content {
margin-top: 20px;
    text-align: center;
}
    
}
@media (max-width: 575.98px) {
    .container-fluid2 {
    padding-right: 15px;
    padding-left: 15px;
}
.vision_img img {
    width: 65%;
}
.vision__buton {
   margin-top: 20px;
    display: block;
}
.vision__buton a{
    margin-bottom: 20px;
}
}


.default-btn {
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 15px 30px 16px;
    line-height: 1;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-align: center;
    background-color: #e21f36;
    position: relative;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-decoration: none;
    /* color: #4b5280; */
}
.default-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,right top,left top,from(#ffffff),to(transparent));
    background: linear-gradient(right,#ffffff,transparent);
    -webkit-animation: right 2s linear infinite;
    animation: right 2s linear infinite;
}
.default-btn span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(transparent));
  background: linear-gradient(top, #ffffff, transparent);
  animation: top 2s linear infinite;
  animation-delay: 1s;
}

.default-btn span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
  background: linear-gradient(bottom, #ffffff, transparent);
  animation: bottom 2s linear infinite;
  animation-delay: 1s;
}
.default-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,left top,right top,from(#ffffff),to(transparent));
    background: linear-gradient(left,#ffffff,transparent);
    -webkit-animation: left 2s linear infinite;
    animation: left 2s linear infinite;
}
@keyframes right {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
    
}
@keyframes top {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
    
}
@keyframes bottom {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
    
}
@keyframes left {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

    
}

`;
export default Wrapper;
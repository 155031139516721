import React, { useRef, useEffect } from 'react';
import Wrapper from './Banner.styled'
import Game from "../../images/animebg.gif"
import AOS from 'aos';
import 'aos/dist/aos.css';
import BgVideo from"../../images/handshake.mp4"

function Banner() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with your preferred options
  }, []);


  const videoRef = useRef(null);

  useEffect(() => {
    // Loop the video when it ends
    const handleLoop = () => {
      videoRef.current.play();
    };

    videoRef.current.addEventListener('ended', handleLoop);

    return () => {
      videoRef.current.removeEventListener('ended', handleLoop);
    };
  }, []);





  return (
   <Wrapper>
    <section className="banner">
    <div className="background-video">
    <div className="video-overlay"></div>
      <video ref={videoRef} autoPlay muted loop>
        <source src={BgVideo} type="video/mp4" />
        {/* Add additional source elements for different video formats */}
      </video>
    </div>
      <div className="container-fluid2">
        <div className="row">
      
          <div className="col-md-6">
            <div className="banner_Content_single_item">
              <div className="banner_Title"  data-aos="fade-up" data-aos-duration="1000">
                <h3>AI : A NEW PARADIGM IN INNOVATION</h3>
              </div>
              <div className="banner_pera"data-aos="fade-up" data-aos-duration="1500">
                <p>Ethan Token has been developed purely for the purpose of amusement and entertainment and is solely intended to provide enjoyment and to bring stability and honor to the world of memes Tokens. Supported on the BEP20 – BSE Chain block chain, Ethan Token, is also signified as the $ETHAN token.</p>
              </div>
              <div className="banner_button"data-aos="fade-up" data-aos-duration="2500">
                <a href="https://bscscan.com/token/0xf9f3336975a587688e6d37b0f36a4e7a0d209abc" target="_blank" className='default-btn'><span>View on BSCSCAN</span></a>
                <a href="https://pancakeswap.finance/swap?outputCurrency=0xF9F3336975a587688E6d37b0f36A4E7A0d209Abc" target="_blank" className='newSkewButton default-btn'><span className='gradient-text skewNew'>Buy/Sell</span></a>
              </div>
         
            </div>
          </div>
          <div className="col-md-6">
            <div className="banner__img"data-aos="zoom-in" data-aos-duration="1200">
            <img 
            src={Game} 
            className='img-fluid' 
            loading='lazy' 
            alt="console" 
            style={{ width: '600px', height: 'auto' }} 
          />

            </div>
          </div>


       
        </div>
      </div>
      {/* <div className="right_single_item">
              <div className="banner_img">
                <img src={Game} className='img-fluid' loading='lazy' alt="console"  />
              </div>
            
            </div> */}
            
    </section>
    
    </Wrapper>
  )
}

export default Banner

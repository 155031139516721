import React, { useEffect, useState } from "react";
import Preloader from "../Components/Preloader/Preloader";
import Wrapper from "./Home.styled";
import Navbar from "../Components/Navbar/Navbar";
import Banner from "../Components/Banner/Banner";
import InfiniteSlider from "../Components/InfiniteSlider/InfiniteSlider";
import About from "../Components/About/About";
import Tokenmics from "../Components/Tokenmics/Tokenmics";
import Advantage from "../Components/Advantage/Advantage";
import Roadmap from "../Components/Roadmap/Roadmap";
import Partner from "../Components/Partner/Partner";
import Marque from "../Components/Marque/Marque";
import Footer from "../Components/Footer/Footer";
import Vision from "../Components/Vision/Vision";

function Home() {
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(() => {
        // Simulate loading completion after a certain delay
        const timeoutId = setTimeout(() => {
            setLoadingComplete(true);
        }, 1000); // Adjust the delay as needed

        // Clear the timeout to prevent memory leaks
        return () => clearTimeout(timeoutId);
    }, []);

    // Render the preloader only when loading is in progress
    if (!loadingComplete) {
        return <Preloader />;
    }

    // Once loading is complete, render the main content
    return (
        <Wrapper>
            <Navbar />
            <Banner />
            <InfiniteSlider />
            <Vision />
            <About />
            <Tokenmics />
            <Partner />
            <Roadmap />
            <Advantage  />
            <Marque />
            <Footer />
        </Wrapper>
    );
}

export default Home;

/** @format */


import styled from "styled-components";

const Wrapper = styled.div `
.advantage{
    padding: 80px 0px;
    position: relative;
}
.row{
    align-items: center;
}
.advantage_item{
    padding-right: 80px;
}
.advantage_item h4{
    font-size: 36px;
    font-weight: 700;
    color: white;

}
.advantage_item p{
    color: #dddedc;
    padding: 20px 0px;
}
@media (max-width: 1192px) {
  .advantage_item {
    padding-right: 0px;
}
.container-fluid2 {

    padding-right: 60px;
    padding-left: 60px;

}
}
@media (max-width: 992px) {
  .advantage_item {
    padding-right: 0px;
}
.container-fluid2 {

    padding-right: 40px;
    padding-left: 40px;

}
 .advantage_item h4 {
    font-size: 27px;

}
.advantage_item p {
    color: #cacaca;
    font-size: 14px;
}

}
@media (max-width: 767.98px) {
.col-md-5,.col-md-7{
    flex: 0 0 100%;
    max-width: 100%;
}
.row{
    flex-direction: column-reverse;
}
 .advantage_item {
    text-align: center;
    margin-top: 40px;
}
}
@media (max-width: 575.98px) {
    .container-fluid2 {
    padding-right: 15px;
    padding-left: 15px;
}
}
.default-btn {
    z-index: 1;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 15px 30px 16px;
    line-height: 1;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-align: center;
    background-color: #e21f36;
    position: relative;
    overflow: hidden;
    display: inline-block;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    text-decoration: none;
    /* color: #4b5280; */
}
.default-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,right top,left top,from(#ffffff),to(transparent));
    background: linear-gradient(right,#ffffff,transparent);
    -webkit-animation: right 2s linear infinite;
    animation: right 2s linear infinite;
}
.default-btn span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(transparent));
  background: linear-gradient(top, #ffffff, transparent);
  animation: top 2s linear infinite;
  animation-delay: 1s;
}

.default-btn span::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
  background: linear-gradient(bottom, #ffffff, transparent);
  animation: bottom 2s linear infinite;
  animation-delay: 1s;
}
.default-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear,left top,right top,from(#ffffff),to(transparent));
    background: linear-gradient(left,#ffffff,transparent);
    -webkit-animation: left 2s linear infinite;
    animation: left 2s linear infinite;
}
@keyframes right {
    0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}
100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
    
}
@keyframes top {
    0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
    
}
@keyframes bottom {
    0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}
    
}
@keyframes left {
    0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}
100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

    
}
.background-video {

  z-index: -2;
  overflow: hidden;
}

.background-video video {

  width: 100%;
  height: auto;

}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15); /* Black overlay with 5% opacity */
  z-index: 1;
}

`;
export default Wrapper;
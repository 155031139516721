/** @format */


import styled from "styled-components";

import SliderBg from "../../images/sliderbg.png"
const Wrapper = styled.div `

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
 background-color: #0e0e0d;
  background-repeat: no-repeat;
  background-size: cover;

  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  transform: skew(0deg, -1deg);
  -webkit-box-shadow: 24px -47px 89px 34px rgba(0,0,0,0.75);
-moz-box-shadow: 24px -47px 89px 34px rgba(0,0,0,0.75);
box-shadow: 24px -47px 89px 34px rgba(0,0,0,0.75);
margin-top: 20px;
z-index: 65656;

position: relative;
top: -55px;
@media (max-width:575.98px) {
  top: -25px;
  
}

}
.slider::before, .slider::after {
  /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";

  position: absolute;

  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
 .slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: calc(1220px * 8);
    column-gap: 50px;
    align-items: center;
    padding: 20px 0px;
}

.slider .slide {

  /* width: 680px; */
}
.slide a{
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 25px;
}
.slider_text{
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.star{
  display: flex;
  align-items: center;
  width: 40px;
}
.star img{}
.text_slide{}
.text_slide h4{
  font-weight: 500;
}
@media (max-width:992px) {
 .slider .slide-track {
  
    width: calc(660px * 16);
  
}
    
}
`;
export default Wrapper;